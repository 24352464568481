<template>
    <div class="animated fadeIn">
        <b-card>
            <b-card-header>
                Crypto Asset Pricing Treasury
            </b-card-header>
            <b-card-body>
              <b-row class="mb-4">
                <b-col>
                  <router-link :to="{name: 'Add Crypto Pricing'}" class="btn btn-primary" v-if="$can('create_pricing')"><i class="fa fa-plus"></i> Create Pricing</router-link>
                </b-col>
              </b-row>
                <div class="loading" v-if="isLoading == true">
                    <div class="sk-three-bounce">
                        <div class="sk-child sk-bounce1"></div>
                        <div class="sk-child sk-bounce2"></div>
                        <div class="sk-child sk-bounce3"></div>
                    </div>
                </div>
                <b-row class="mb-5">
                    <b-col>
                        <h4 class="mb-5">Harga Asset Crypto Hari ini : {{ this.crypto_date }}</h4>
                        <b-select
                          v-model="menu"
                          :options="cryptoOption"
                        ></b-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <h6>Price Before Margin (TokoCrypto - Active Crypto Resource)</h6>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <b-card :no-body="true">
                        <b-card-body class="p-0 clearfix">
                            <i class="fa fa-tags bg-primary p-4 font-2xl mr-3 float-left"></i>
                            <div class="text-muted text-uppercase font-weight-bold font-xs pt-3">Asset Buy Price</div>
                            <div class="h6 text-primary mb-0">IDR : {{ this.crypto_price_before_margin.buying_rate | currency }}</div>
                        </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col sm="4">
                        <b-card :no-body="true">
                        <b-card-body class="p-0 clearfix">
                            <i class="fa fa-tags bg-info p-4 font-2xl mr-3 float-left"></i>
                            <div class="text-muted text-uppercase font-weight-bold font-xs pt-3">Asset Sell Price</div>
                            <div class="h6 text-primary mb-0">IDR : {{ this.crypto_price_before_margin.selling_rate | currency }}</div>
                        </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col sm="4">
                        <b-card :no-body="true">
                        <b-card-body class="p-0 clearfix">
                            <i class="fa fa-tags bg-info p-4 font-2xl mr-3 float-left"></i>
                            <div class="text-muted text-uppercase font-weight-bold font-xs pt-3">Asset Spread</div>
                            <div class="h5 text-primary mb-0">
                              {{
                                Math.round(
                                  (
                                    (
                                      (
                                        this.crypto_price_before_margin.buying_rate - this.crypto_price_before_margin.selling_rate
                                      ) / this.crypto_price_before_margin.buying_rate * 100
                                    ) + Number.EPSILON
                                  ) * 100
                                ) / 100 }} %
                            </div>
                        </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <h6>Price After Margin</h6>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <b-card :no-body="true">
                        <b-card-body class="p-0 clearfix">
                            <i class="fa fa-money bg-success p-4 font-2xl mr-3 float-left"></i>
                            <div class="text-muted text-uppercase font-weight-bold font-xs pt-3">Asset Buy Price</div>
                            <div class="h5 text-primary mb-0">IDR : {{ this.crypto_price.buying_rate | currency }}</div>
                            <div class="text-muted text-uppercase font-weight-bold font-xs">Asset Buy Price saat ini</div>
                        </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col sm="4">
                        <b-card :no-body="true">
                        <b-card-body class="p-0 clearfix">
                            <i class="fa fa-money bg-danger p-4 font-2xl mr-3 float-left"></i>
                            <div class="text-muted text-uppercase font-weight-bold font-xs pt-3">Asset Sell Price</div>
                            <div class="h5 text-info mb-0">IDR : {{ this.crypto_price.selling_rate | currency }}</div>
                            <div class="text-muted text-uppercase font-weight-bold font-xs">Asset Sell Price saat ini</div>
                        </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col sm="4">
                        <b-card :no-body="true">
                        <b-card-body class="p-0 clearfix">
                            <i class="fa fa-tags bg-info p-4 font-2xl mr-3 float-left"></i>
                            <div class="text-muted text-uppercase font-weight-bold font-xs pt-3">Asset Spread</div>
                            <div class="h5 text-primary mb-0">
                              {{
                                Math.round(
                                  (
                                    (
                                      (
                                        this.crypto_price.buying_rate - this.crypto_price.selling_rate
                                      ) / this.crypto_price.buying_rate * 100
                                    ) + Number.EPSILON
                                  ) * 100
                                ) / 100 }} %
                            </div>
                        </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>

                <b-row class="mb-4">
                  <b-col>
                    <h3>Master Pricing</h3>
                    <b-row>
                      <b-col sm="4">
                        <h6>Created By</h6>
                        <div class="mb-3" v-if="this.crypto_price_master.created_by == null && typeof this.crypto_price_master.created_by === 'undefined' "><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.crypto_price_master.created_by}}</strong></div>
                      </b-col>
                      <b-col sm="4">
                        <h6>Type</h6>
                        <div class="mb-3" v-if="this.crypto_price_master.type == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.crypto_price_master.type.toUpperCase()}}</strong></div>
                      </b-col>
                      <b-col sm="4">
                        <h6>Created at</h6>
                        <div class="mb-3" v-if="this.crypto_price_master.created_at == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.crypto_price_master.created_at.toUpperCase()}}</strong></div>
                      </b-col>
                    </b-row>
                    <b-row v-if="this.crypto_price_master.type == 'combination'">
                      <b-col sm="4">
                        <h6>Margin Buy (%)</h6>
                        <div class="mb-3" v-if="this.crypto_price_master.margin_ask == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.crypto_price_master.margin_ask}}%</strong></div>
                        <h6>Margin Buy IDR (Rp)</h6>
                        <div class="mb-3" v-if="this.crypto_price_master.margin_ask_combination == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.crypto_price_master.margin_ask_combination | currency}}</strong></div>
                      </b-col>
                      <b-col sm="4">
                        <h6>Margin Sell (%)</h6>
                        <div class="mb-3" v-if="this.crypto_price_master.margin_bid == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.crypto_price_master.margin_bid}}%</strong></div>
                        <h6>Margin Sell IDR (Rp)</h6>
                        <div class="mb-3" v-if="this.crypto_price_master.margin_bid_combination == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.crypto_price_master.margin_bid_combination | currency}}</strong></div>
                      </b-col>
                      <b-col sm="4">
                        <h6>Trading Fee (%)</h6>
                        <div class="mb-3" v-if="this.crypto_price_master.trading_fee == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.crypto_price_master.trading_fee}}%</strong></div>
                        <h6>Trading Fee IDR (Rp)</h6>
                        <div class="mb-3" v-if="this.crypto_price_master.trading_fee_combination == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.crypto_price_master.trading_fee_combination | currency}}</strong></div>
                      </b-col>
                    </b-row>
                    <b-row v-if="this.crypto_price_master.type == 'amount'">
                      <b-col sm="4">
                        <h6>Margin Buy IDR (Rp)</h6>
                        <div class="mb-3" v-if="this.crypto_price_master.margin_ask_combination == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.crypto_price_master.margin_ask_combination | currency}}</strong></div>
                      </b-col>
                      <b-col sm="4">
                        <h6>Margin Sell IDR (Rp)</h6>
                        <div class="mb-3" v-if="this.crypto_price_master.margin_bid_combination == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.crypto_price_master.margin_bid_combination | currency}}</strong></div>
                      </b-col>
                      <b-col sm="4">
                        <h6>Trading Fee IDR (Rp)</h6>
                        <div class="mb-3" v-if="this.crypto_price_master.trading_fee_combination == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.crypto_price_master.trading_fee_combination | currency}}</strong></div>
                      </b-col>
                    </b-row>
                    <b-row v-if="this.crypto_price_master.type == 'percentage'">
                      <b-col sm="4">
                        <h6>Margin Buy (%)</h6>
                        <div class="mb-3" v-if="this.crypto_price_master.margin_ask == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.crypto_price_master.margin_ask}}%</strong></div>
                      </b-col>
                      <b-col sm="4">
                        <h6>Margin Sell (%)</h6>
                        <div class="mb-3" v-if="this.crypto_price_master.margin_bid == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.crypto_price_master.margin_bid}}%</strong></div>
                      </b-col>
                      <b-col sm="4">
                        <h6>Trading Fee (%)</h6>
                        <div class="mb-3" v-if="this.crypto_price_master.trading_fee == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.crypto_price_master.trading_fee}}%</strong></div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <h3>Custom Pricing</h3>
                      <b-row class="mb-3" v-if="$can('export')">
                        <b-col sm>
                            <div>
                                <b-form-group
                                    class="mb-3"
                                    label="Export Pricing :"
                                    label-for="daterange"
                                >
                                    <b-input-group
                                    >
                                        <b-input-group-prepend is-text>
                                            <i class="fa fa-calendar"></i>
                                        </b-input-group-prepend>
                                        <range-picker id="daterange" :start="startDate" :end="endDate" @picker="changeDate"></range-picker>
                                        <b-input-group-append>
                                            <button type="button" class="btn btn-success" @click="exportTable" >Export</button>&nbsp;
                                            <b-button type="button" variant="secondary" @click="resetDateFilter">Reset</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </div>
                        </b-col>
                    </b-row>
                    <pricing-table></pricing-table>
                  </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import PricingTable from '@/views/pages/crypto/Table'
import Pusher from 'pusher-js' // import Pusher
import accounting from 'accounting'
import moment from 'moment'
import axios from 'axios'

let startDate = '';
let endDate = '';

export default {
    name: 'Index-crypto',
    components: {
      PricingTable,
    },
    data() {
      return {
        startDate: '',
        endDate: '',
        pricing: '',
        rupiah: '',
        menu: 'KQxz9YXazA14VEO',
        isLoading: false,
        crypto: [],
        crypto_price: {
          buying_rate: 0,
          selling_rate: 0,
          created_at: ''
        },
        crypto_price_before_margin: {
          buying_rate: 0,
          selling_rate: 0
        },
        crypto_price_master: [],
        cryptoOption: [],
        crypto_date: ''
      }
    },
    watch: {
      menu () {
        this.$http
        .get(`crypto-currency?crypto=${this.menu}`)
          .then((res) => {
            let result = res.data.data
            this.crypto_price.buying_rate = result.after.buying_rate
            this.crypto_price.selling_rate = result.after.selling_rate
            this.crypto_price.created_at = result.after.created_at
            this.crypto_price_before_margin.buying_rate = result.before.buying_rate
            this.crypto_price_before_margin.selling_rate = result.before.selling_rate
            this.crypto_price_master = result.margin
            this.crypto_date = moment(this.crypto_price.created_at).format('DD/MM/YYYY HH:mm')
          })
          .catch((error) => {
            if (error.response) {
              this.errors.code = error.response.status;
              this.errors.message = error.response.data.meta.message;
              this.errors.status = error.response.data.meta.code;
            }
          })
      }
    },
    created () {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
      axios.get(process.env.VUE_APP_SECRET + 'admin/v2/command/crypto-command').then((response) => {
        this.cryptoOption = response.data.data
      })
      this.$http
      .get(`crypto-currency`)
        .then((res) => {
          let result = res.data.data
          this.crypto_price.buying_rate = result.after.buying_rate
          this.crypto_price.selling_rate = result.after.selling_rate
          this.crypto_price.created_at = result.after.created_at
          this.crypto_price_before_margin.buying_rate = result.before.buying_rate
          this.crypto_price_before_margin.selling_rate = result.before.selling_rate
          this.crypto_price_master = result.margin
          this.crypto_date = moment(this.crypto_price.created_at).format('DD/MM/YYYY HH:mm')
        })
        .catch((error) => {
          if (error.response) {
            this.errors.code = error.response.status;
            this.errors.message = error.response.data.meta.message;
            this.errors.status = error.response.data.meta.code;
          }
        })

      this.subscribe()
    },
    methods: {
      formatNumber (value) {
        return value != null ? 'Rp.'+accounting.formatNumber(value, 2) : '-'
      },
      formatedRupiah(number){
        if (number != null) {
          var reverse = number.toString().split('').reverse().join(''),
          thousand = reverse.match(/\d{1,3}/g);
          thousand = thousand.join('.').split('').reverse().join('');
          return 'Rp. ' + thousand;
        } else {
          return 'Rp. ' + 0;
        }

      },
      subscribe () {
        let that = this;
        let pusher = new Pusher('52e99bd2c3c42e577e13', { cluster: 'ap1', forceTLS: true })

        pusher.subscribe('crypto-rate')
        pusher.bind('grafik-crypto-event', data => {
          let filtered = data.filter(v => v.crypto_id === that.menu)
          that.crypto_price.buying_rate = filtered[0].buying_rate
          that.crypto_price.selling_rate = filtered[0].selling_rate
          that.crypto_price_before_margin.buying_rate = filtered[0].buying_rate_pure
          that.crypto_price_before_margin.selling_rate = filtered[0].selling_rate_pure
          that.crypto_price.created_at = filtered[0].created_at
          that.crypto_date = moment(that.crypto_price.created_at).format('DD/MM/YYYY HH:mm')
        })
      },
      changeDate (value) {
          this.startDate = value.startDate;
          this.endDate = value.endDate;
      },
      exportTable() {
          this.isLoading = true
          if (this.startDate != '' && this.endDate != '') {
              startDate = this.startDate;
              endDate = this.endDate;
          }else{
              endDate = this.$setDate.format('YYYY-MM-DD');
              startDate = this.$setDate.subtract(29, 'days').format('YYYY-MM-DD');
          }
          this.$http.get(`crypto-export/` + startDate + `/` + endDate)
          .then((result) => {
              this.isLoading = false
              const  exportPath = result.data.meta.data;
              window.location.href = exportPath;
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
            }
          })
      },
      resetDateFilter () {
          this.startDate = ''
          this.endDate = ''
      },
    },
}
</script>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
